// import React, { useState, useContext } from 'react';
// import { Link} from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import reg from './images/reg.png';

// function Registerpage() {
//   const [email, setEmail] = useState('');
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [password2, setPassword2] = useState('');
//   const [course, setCourse] = useState('civil'); // Default to civil engineering
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState('');
  
//   const { registerUser } = useContext(AuthContext);

  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
//   };

//   const handlePaymentDetailsChange = (e) => {
//     setPaymentDetails(e.target.files[0]); // Handle image upload
//   };

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: '1rem' }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: '100%',
//                         height: 'auto',
//                         borderRadius: '1rem 0 0 1rem',
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: '#ff6219' }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome</span>
//                         </div>
//                         <h5
//                           className="fw-normal mb-3 pb-3"
//                           style={{ letterSpacing: 1 }}
//                         >
//                           Sign Up
//                         </h5>

//                         {/* Email */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             onChange={(e) => setEmail(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Username */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="username"
//                             className="form-control form-control-lg"
//                             placeholder="Your Name"
//                             onChange={(e) => setUsername(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password"
//                             onChange={(e) => setPassword(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Confirm Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password2"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             onChange={(e) => setPassword2(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Course Dropdown */}
//                         <div className="form-outline mb-4">
//                           <select
//                             id="course"
//                             className="form-control form-control-lg"
//                             onChange={(e) => setCourse(e.target.value)}
//                             required
//                           >
//                             <option value="civil">Civil NEC license</option>
//                             <option value="geomatics">Geomatics license</option>
//                           </select>
//                         </div>

//                         {/* Phone Number */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="phone_number"
//                             className="form-control form-control-lg"
//                             placeholder="Phone Number"
//                             maxLength="15"
//                             onChange={(e) => setPhoneNumber(e.target.value)}
//                           />
//                         </div>

//                         {/* Payment Details (Image Upload) */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             id="payment_details"
//                             className="form-control form-control-lg"
//                             accept="image/*"
//                             onChange={handlePaymentDetailsChange}
//                           />
//                         </div>

//                         {/* Submit Button */}
//                         <div className="pt-1 mb-4">
//                           <button
//                             className="btn btn-dark btn-lg btn-block"
//                             type="submit"
//                           >
//                             Register
//                           </button>
//                         </div>

//                         <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
//                           Already have an account?{' '}
//                           <Link to="/login" style={{ color: '#393f81' }}>
//                             Login Now
//                           </Link>
//                         </p>

//                         <a href="#!" className="small text-muted">
//                           Terms of use
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div
//           className="text-center p-3"
//           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//         >
//           © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Registerpage;




//this is commented out code

// import React, { useState, useContext, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import reg from './images/reg.png';
// import QRPopup from './QRPopup'; 

// function Registerpage() {
//   const [email, setEmail] = useState('');
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [password2, setPassword2] = useState('');
//   const [course, setCourse] = useState('civil'); // Default to civil engineering
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
//   const [showPopup, setShowPopup] = useState(false); // State to control the QR Popup


//   const { registerUser } = useContext(AuthContext);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
//     setHasUnsavedChanges(false); // Reset unsaved changes after submission
//   };

//   const handlePaymentDetailsChange = (e) => {
//     setPaymentDetails(e.target.files[0]); // Handle image upload
//     setHasUnsavedChanges(true);
//   };

  
//   const handlePopupClose = () => {
//     setShowPopup(false); // Close popup
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (hasUnsavedChanges) {
//         const message = "You have unsaved changes. Are you sure you want to leave?";
//         event.returnValue = message; // Most browsers display a generic message
//         return message;
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [hasUnsavedChanges]);

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: '1rem' }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: '100%',
//                         height: 'auto',
//                         borderRadius: '1rem 0 0 1rem',
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: '#ff6219' }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome</span>
//                         </div>
//                         <h5
//                           className="fw-normal mb-3 pb-3"
//                           style={{ letterSpacing: 1 }}
//                         >
//                           Sign Up
//                         </h5>

//                         {/* Email */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             onChange={(e) => {
//                               setEmail(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Username */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="username"
//                             className="form-control form-control-lg"
//                             placeholder="Your Name"
//                             onChange={(e) => {
//                               setUsername(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password at least 8 character"
//                             onChange={(e) => {
//                               setPassword(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                           <p>at least 8 character</p>
//                         </div>

//                         {/* Confirm Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password2"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             onChange={(e) => {
//                               setPassword2(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Course Dropdown */}
//                         <div className="form-outline mb-4">
//                           <select
//                             id="course"
//                             className="form-control form-control-lg"
//                             onChange={(e) => {
//                               setCourse(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           >
//                             <option value="civil">Civil NEC license</option>
//                             <option value="geomatics">Geomatics license</option>
//                           </select>
//                         </div>

//                         {/* Phone Number */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="phone_number"
//                             className="form-control form-control-lg"
//                             placeholder="Phone Number"
//                             maxLength="15"
//                             onChange={(e) => {
//                               setPhoneNumber(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                           />
//                         </div>
//                                               {/* Payment Popup Button */}
//                         <div className="form-outline mb-4">
//                           <button
//                             type="button"
//                             className="btn btn-success btn-lg btn-block"
//                             onClick={() => setShowPopup(true)}
//                           >
//                             Click here for payment
//                           </button>
//                           {showPopup && <QRPopup onClose={handlePopupClose} />}
//                         </div>

//                         {/* Payment Details (Image Upload) */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             id="payment_details"
//                             className="form-control form-control-lg"
//                             accept="image/*"
//                             onChange={handlePaymentDetailsChange}
//                           />
//                           <p>Attach the screen shot of the payment detail here</p>
//                         </div>

//                         {/* Submit Button */}
//                         <div className="pt-1 mb-4">
//                           <button
//                             className="btn btn-dark btn-lg btn-block"
//                             type="submit"
//                           >
//                             Register
//                           </button>
//                         </div>

//                         <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
//                           Already have an account?{' '}
//                           <Link to="/login" style={{ color: '#393f81' }}>
//                             Login Now
//                           </Link>
//                         </p>

//                         <a href="#!" className="small text-muted">
//                           Terms of use
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div
//           className="text-center p-3"
//           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//         >
//           © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Registerpage;




//This is the test code


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import './rp.css'


// const RegisterPage = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     validity: "",
//     payment_detail: null, // File input
//   });

//   const [courses, setCourses] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState("");

//   // Fetch available courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get("https://nec.geoneer.com.np/api/accounts/courses/");
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   // Handle form input changes
//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === "payment_detail") {
//       setFormData({ ...formData, [name]: files[0] });
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formDataToSend = new FormData();
//     for (const key in formData) {
//       formDataToSend.append(key, formData[key]);
//     }

//     try {
//       const response = await axios.post("https://nec.geoneer.com.np/api/accounts/register/", formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setSuccessMessage(response.data.message);
//       setErrors({});
//       setFormData({
//         email: "",
//         first_name: "",
//         last_name: "",
//         password: "",
//         password_confirmation: "",
//         course: "",
//         validity: "",
//         payment_detail: null,
//       });
//     } catch (error) {
//       if (error.response && error.response.data) {
//         setErrors(error.response.data);
//       }
//     }
//   };

//   return (
//     <div className="register-container">
//       <h2>Register</h2>
//       {successMessage && <p className="success-message">{successMessage}</p>}
//       <form onSubmit={handleSubmit} encType="multipart/form-data">
//         <div>
//           <label>Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//           />
//           {errors.email && <p className="error">{errors.email[0]}</p>}
//         </div>

//         <div>
//           <label>First Name</label>
//           <input
//             type="text"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Last Name</label>
//           <input
//             type="text"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Password</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//           />
//           {errors.password && <p className="error">{errors.password[0]}</p>}
//         </div>

//         <div>
//           <label>Confirm Password</label>
//           <input
//             type="password"
//             name="password_confirmation"
//             value={formData.password_confirmation}
//             onChange={handleChange}
//           />
//           {errors.password_confirmation && (
//             <p className="error">{errors.password_confirmation[0]}</p>
//           )}
//         </div>

//         <div>
//           <label>Course</label>
//           <select name="course" value={formData.course} onChange={handleChange}>
//             <option value="">Select a course</option>
//             {courses.map((course) => (
//               <option key={course.id} value={course.id}>
//                 {course.courseName}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div>
//           <label>Validity</label>
//           <input
//             type="date"
//             name="validity"
//             value={formData.validity}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Payment Detail</label>
//           <input
//             type="file"
//             name="payment_detail"
//             accept="image/*"
//             onChange={handleChange}
//           />
//         </div>

//         <button type="submit">Register</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterPage;

// import React, { useState, useEffect, useContext } from "react";
// import AuthContext from "../context/AuthContext";
// import axios from "axios";

// const RegisterPage = () => {
//   const { registerUser } = useContext(AuthContext);

//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     validity: "",
//     payment_detail: null, // File input
//   });

//   const [courses, setCourses] = useState([]);

//   // Fetch available courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://nec.geoneer.com.np/api/accounts/courses/"
//         );
//         setCourses(response.data); // Assuming response.data is the array of courses
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       payment_detail: e.target.files[0],
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Create FormData and append all fields
//     const data = new FormData();
//     data.append("email", formData.email);
//     data.append("first_name", formData.first_name);
//     data.append("last_name", formData.last_name);
//     data.append("password", formData.password);
//     data.append("password_confirmation", formData.password_confirmation);
//     data.append("course", formData.course);
//     data.append("validity", formData.validity);
//     if (formData.payment_detail) {
//       data.append("payment_detail", formData.payment_detail);
//     }

//     try {
//       // Debugging: Log the data for verification
//       for (const [key, value] of data.entries()) {
//         console.log(`${key}: ${value}`);
//       }

//       // Send registration data
//       const response = await axios.post(
//         "https://nec.geoneer.com.np/api/accounts/register/",
//         data,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       console.log("Registration successful:", response.data);
//     } catch (error) {
//       if (error.response) {
//         console.error("Error during registration:", error.response.data);
//       } else {
//         console.error("Error:", error.message);
//       }
//     }
//   };

//   return (
//     <div className="register-page">
//       <form onSubmit={handleSubmit}>
//         <h2>Register</h2>
//         <div>
//           <label>Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>First Name:</label>
//           <input
//             type="text"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Last Name:</label>
//           <input
//             type="text"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Password:</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Confirm Password:</label>
//           <input
//             type="password"
//             name="password_confirmation"
//             value={formData.password_confirmation}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Course:</label>
//           <select
//             name="course"
//             value={formData.course}
//             onChange={handleChange}
//             required
//           >
//             <option value="">Select a course</option>
//             {courses.map((course) => (
//               <option key={course.id} value={course.id}>
//                 {course.courseName}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div>
//           <label>Validity:</label>
//           <input
//             type="text"
//             name="validity"
//             value={formData.validity}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Payment Details:</label>
//           <input
//             type="file"
//             name="payment_detail"
//             onChange={handleFileChange}
//           />
//         </div>
//         <button type="submit">Register</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterPage;



import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import reg from "./images/reg.png"; // Adjust the path to your image as needed
import QRPopup from "./QRPopup"; // Assuming QRPopup is a separate component for displaying a QR code
import { Link } from 'react-router-dom';

const RegisterPage = () => {
  const { registerUser } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    course: "",
    phone_number: "",
    payment_detail: null, // File input
  });

  const [courses, setCourses] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false);



  // Fetch available courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://nec.geoneer.com.np/api/accounts/courses/"
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setHasUnsavedChanges(true);
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      payment_detail: e.target.files[0],
    }));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) data.append(key, formData[key]);
    });

    registerUser(data); // Call the context function to handle registration
    setHasUnsavedChanges(false);
  };

  const handlePopupClose = () => setShowPopup(false);



  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // For most browsers
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  return (
    <div>
      <section className="vh-100" style={{ backgroundColor: "#147d04" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src={reg}
                      alt="registration form"
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "1rem 0 0 1rem",
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <i
                            className="fas fa-cubes fa-2x me-3"
                            style={{ color: "#ff6219" }}
                          />
                          <span className="h2 fw-bold mb-0">Register</span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
                          Create Your Account
                        </h5>

                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-lg"
                            placeholder="Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="first_name"
                            className="form-control form-control-lg"
                            placeholder="First Name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            name="last_name"
                            className="form-control form-control-lg"
                            placeholder="Last Name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="password"
                            name="password"
                            className="form-control form-control-lg"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <input
                            type="password"
                            name="password_confirmation"
                            className="form-control form-control-lg"
                            placeholder="Confirm Password"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <select
                            name="course"
                            className="form-control form-control-lg"
                            value={formData.course}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Course</option>
                            {courses.map((course) => (
                              <option key={course.id} value={course.id}>
                                {course.courseName}
                              </option>
                            ))}
                          </select>
                        </div>
                                             { /* Phone Number */ }
                                             <div className="form-outline mb-4">
                              <input
                                type="text"
                                name="phone_number" // Updated to 'name' for consistency
                                className="form-control form-control-lg"
                                placeholder="Phone Number"
                                maxLength="15"
                                value={formData.phone_number}
                                onChange={handleChange}
                                required
                              />
                            </div>


                                              {/* Payment Popup Button */}
                        <div className="form-outline mb-4">
                          <button
                            type="button"
                            className="btn btn-success btn-lg btn-block"
                            onClick={() => setShowPopup(true)}
                          >
                            Click here for payment
                          </button>
                          {showPopup && <QRPopup onClose={handlePopupClose} />}
                        </div>

                   
                       

                        <div className="form-outline mb-4">
                          <input
                            type="file"
                            name="payment_detail"
                            className="form-control form-control-lg"
                            onChange={handleFileChange}
                          />
                       <p>Attach the screen shot of the payment detail here</p>

                        </div>

                        <button className="btn btn-dark btn-lg btn-block" type="submit">
                          Register
                        </button>
                        <p className="mt-2">
                           Already have an account? <Link to="/login">Login</Link>
                        </p>
                      </form>

                      {showPopup && <QRPopup onClose={handlePopupClose} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterPage;



